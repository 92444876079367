import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

define([
    "knockout",
     "services/materialRequisitionService",
     "widgets/pages/material_requisition"

], function (ko, materialRequisitionService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.requisitionId = ko.observable();
            self.workOrderId = ko.observable();
            //#endregion
        }

        //#region Public Functions
        function setSpecificFields(item) {
            item.workOrderId = self.workOrderId();
            return item;
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params ) {
            self.workOrderId(params.workOrderId);
            self.requisitionId(params.requisitionId === null ? "-1" : params.requisitionId);

            var paramsQs = routerHelper.getQuerystring(params.q);
            self.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;

            if (paramsQs && paramsQs.readonly) {
                self.readonly = queryStringHelper.parseReadonly(params.q);
            }

            self.actions = {
                getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(self, self.requisitionId()),
                saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForFieldService.bind(self, self.workOrderId()),
                setSpecificFields: setSpecificFields
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
