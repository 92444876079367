import { I18N } from "aurelia-i18n";
import { inject } from "aurelia-framework";

import ko from "knockout";
import jQuery from "jquery";
import routerHelper from "helpers/routerHelper";
import dateHelper from "helpers/dateHelper";
import dispatch from "services/dispatchService";
import notifier from "helpers/notificationHelper";
import queryStringHelper from "helpers/queryStringHelper";
import { NotificationHelper } from "helpers/notification-helper";
import { default as stringHelper } from "helpers/stringHelper";
import { ValidationError } from "api/enums/validation-error";

import "widgets/dateTimePicker";

@inject(I18N, NotificationHelper)
export class DetailHoursEdit {
    i18n;
    serviceType = "";
    readonly = true;
    dateFormat = 'time';
    increment = ko.observable();
    dispatchId = ko.observable();
    labelFrom = ko.observable();
    isTo = ko.observable(true);
    labelTo = ko.observable();
    routingParam = "";
    oneDayWorkDuration = false;

    dateFrom = ko.observable().extend({ date: true });
    dateTo = ko.observable();
    errorMessage = "";
    errorMessageTo = "";
    validatedForm;

    constructor(i18n, notificationHelper) {
        this.notificationHelper = notificationHelper;
        this.i18n = i18n;
        this.validatedForm = ko.validatedObservable({
            dateFrom: ko.observable().extend({ date: true }),
            dateTo: ko.observable(),
            errorMessage: "",
            errorMessageTo: ""
        });
    }

    bindQueryString(queryString) {
        this.readonly = queryStringHelper.parseReadonly(queryString);
    }

    bindViewModel(serviceType, dispatchId, dateFrom, dateTo, increment) {
        this.serviceType = serviceType;
        this.dispatchId(dispatchId);
        this.validatedForm().dateFrom(dateFrom);
        this.validatedForm().dateTo(dateTo);
        this.increment(increment);
        //Get the time gap between Travel Start Time and Estimate Arrival Time
        this.diff = dateHelper.getTimeSpanInHour(dateFrom, dateTo);
        this.oneDayWorkDuration = !dateHelper.isAfter(dateTo, dateFrom);

        switch (this.routingParam.name) {
            case "Service_Detail_Action_Road":
                this.labelFrom(this.i18n.tr("TravelStartTime"));
                this.labelTo(this.i18n.tr("EstimatedArrivalTime"));
                this.isTo(true);
                break;

            case "Service_Detail_Action_Start":
                this.labelFrom(this.i18n.tr("ArrivalTime"));
                this.labelTo(this.i18n.tr("EstimatedCompletedTime"));
                this.isTo(true);
                this.dateFormat = this.oneDayWorkDuration ? 'time' : 'datetime';
                break;
        }
        this.validatedForm().errorMessage = this.labelTo() + this.i18n.tr("err_GreatherThan") + this.labelFrom();
    }

    activate(params, routingParam) {
        this.routingParam = routingParam;
        this.bindQueryString(params.q);
        this.bindViewModel(params.serviceType, params.dispatchId, decodeURIComponent(params.dateFrom), decodeURIComponent(params.dateTo), params.increment);
    }

    async save() {
        if (!this.validatedForm.isValid()) {
            notifier.showValidationError(this.validatedForm.errors);
            return;
        }

        var dFrom = dateHelper.getTime(this.validatedForm().dateFrom());
        var dTo = dateHelper.getTime(this.validatedForm().dateTo());

        if (this.routingParam.name === "Service_Detail_Action_Road" && dFrom > dTo) {
            const success = await this.notificationHelper.showDialogYesNo(this.i18n.tr("msg_Service_On_Road_DateFromGreaterThanEndDate"));
            if (!success) {
                return;
            }
        }

        if (this.routingParam.name === "Service_Detail_Action_Start" && dFrom > dTo) {
            const success = await this.notificationHelper.showDialogYesNo(this.i18n.tr("msg_Service_On_Start_DateFromGreaterThanEndDate"));
            if (!success) {
                return;
            }
        }

        routerHelper.showLoading();

        var dfd = new jQuery.Deferred();

        if (this.routingParam.name === "Service_Detail_Action_Road") {
            dfd = dispatch.setServiceCallEnRoute(this.dispatchId(), dFrom, dTo)
                .done(() => {
                    dispatch.serviceCallEnRouteNotify(this.dispatchId());
                }).fail((ex) => {
                    if (stringHelper.startsWith(ex.responseJSON.Exception.Message, "Dispatch model not found:")) {
                        this.notificationHelper.showError(this.i18n.tr("DispatchModel") + " : " + ex.responseJSON.Exception.Message.split(':')[1], "", { timeOut: 0 });
                    }
                });
        } else if (this.routingParam.name === "Service_Detail_Action_Start") {
            dfd = dispatch.setServiceCallOnSite(this.dispatchId(), dFrom, dateHelper.formatDateToSend(this.validatedForm().dateTo()))
                .done((backOrderItems) => {
                    if (backOrderItems !== undefined && backOrderItems !== null && backOrderItems.length > 0) {
                        var warning = this.i18n.tr("msg_BackOrderMaterial");
                        var backOrderItemsDescription = "";
                        backOrderItems.forEach((boItem) => { backOrderItemsDescription = backOrderItemsDescription + boItem.Id + " - " + boItem.Description + "<br>"; });
                        warning = warning.replace("{0}", backOrderItemsDescription);
                        this.notificationHelper.showWarning(warning, "", { timeOut: 0 });
                    }
                }).fail((ex) => {
                    if (stringHelper.startsWith(ex.responseJSON.Exception.Message, "Dispatch model not found:")) {
                        this.notificationHelper.showError(this.i18n.tr("DispatchModel") + " : " + ex.responseJSON.Exception.Message.split(':')[1], "", { timeOut: 0 });
                    }
                });
        } else {
            dfd.resolve();
        }

        dfd.done(() => { routerHelper.navigateBack(); })
            .always(() => { routerHelper.hideLoading(); });
    }

    enterCurrentTime() {
        var startHour = dateHelper.getNow();
        var endhour = dateHelper.getNow();

        if (this.diff > 0) {
            endhour.add(this.diff, 'hours');
        }

        startHour = dateHelper.formatDate(null, startHour);
        endhour = dateHelper.formatDate(null, endhour);

        //affichage
        this.validatedForm().dateFrom(dateHelper.setHoursMinOnDate(this.validatedForm().dateFrom(), startHour.getHours(), startHour.getMinutes()));
        this.validatedForm().dateTo(dateHelper.setHoursMinOnDate(this.validatedForm().dateTo(), endhour.getHours(), endhour.getMinutes()));
    }
}
