import { ProjectEquipmentBillingCatalogCodeEn } from "api/enums/project-equipment-billing-catalog-code-en";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { PagingInfo } from "api/paging-info";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemBase } from "pages/services/quotations/item-base";
import { EquipmentService } from "services/equipment-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { I18N } from "aurelia-i18n";
import { inject, NewInstance } from "aurelia-dependency-injection";
import { computedFrom, observable} from "aurelia-binding";
import { WageTypeString } from "api/enums/wage-type-string";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { ServiceCallQuotationPricingType } from "api/enums/service-call-quotation-pricing-type";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { IRequestConfig } from "models/request-config";

@inject(NewInstance.of(ValidationController))
export class QuotationItemEquipmentBase extends QuotationItemBase {
    @observable()
    public profitMargin: number = 0;

    @observable()
    public sellingPrice: number = 0;

    public readonly serviceCallQuotationPricingType: typeof ServiceCallQuotationPricingType = ServiceCallQuotationPricingType;
    public calculationInProgress: boolean = false;

    @computedFrom("quotation", "quotation.BillingMethod")
    public get isRateTypeRequired(): boolean {
        if (this.quotation === null || this.quotation.ProjectEquipmentBillingCatalogCodeConfiguration  === null) {
            return false;
        }

        return this.quotation.ProjectEquipmentBillingCatalogCodeConfiguration.search(ProjectEquipmentBillingCatalogCodeEn.RateType) !== -1;
    }

    constructor(
        i18n: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        private readonly equipmentService: EquipmentService,
        private readonly validationHelper: ValidationHelper,
        private readonly validationController: ValidationController,
        private readonly serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        private readonly serviceCallContractEquipmentService: ServiceCallContractEquipmentService
    ) {
        super(i18n, serviceCallQuotationService, serviceCallNonBillingReasonService);
    }

    public async onSelectedEquipmentChanged(event: CustomEvent<EquipmentModel>): Promise<void> {
        if (!this.quotation || !this.quotation.Items) {
            return;
        }

        const equipment = event ? event.detail : null;
        this.quotationItem.EquipmentId = equipment ? equipment.Code : null;
        this.quotationItem.Description = equipment ? equipment.Description : null;

        if (this.isRateTypeRequired) {
            this.quotationItem.RateType = WageTypeString.Equipment;
        }

        this.quotationItem.UnitPrice = 0;
        this.sellingPrice = 0;
        this.profitMargin = this.quotation.ProfitMargin;
        this.quotationItem.ProfitMargin = this.quotation.ProfitMargin;
        this.quotationItem = await this.serviceCallQuotationPriceService.GetItemPrices(this.quotation, this.quotationItem);
        if (this.quotation.ProfitMargin === 0) {
            this.sellingPrice = this.quotationItem.UnitPrice;
        } else {
            this.calculationInProgress = true;
            this.quotationItem.ProfitMargin = this.quotation.ProfitMargin;
            this.profitMargin = this.quotation.ProfitMargin;
            this.sellingPrice = this.serviceCallQuotationPriceService.UpdateItemUnitPriceWithProfitMargin(this.quotation.ProfitMargin, this.quotationItem);
            this.calculationInProgress = true;
        }
    }

    public profitMarginChanged(newValue: any, oldValue: any): void {
        const isValueUnchanged = newValue === oldValue;

        if (this.calculationInProgress || !this.quotationItem || isValueUnchanged) {
            return;
        }

        if (newValue === null) {
            this.quotationItem.ProfitMargin = 0;
            newValue = 0;
        } else {
            this.quotationItem.ProfitMargin = newValue;
        }

        if (this.sellingPrice >= 0) {
            this.calculationInProgress = true;
            this.sellingPrice = this.serviceCallQuotationPriceService.UpdateItemUnitPriceWithProfitMargin(newValue, this.quotationItem);
            this.calculationInProgress = false;
        }
    }

    public sellingPriceChanged(newValue: any, oldValue: any): void {
        const isValueUnchanged = newValue === oldValue;

        if (this.calculationInProgress  || !this.quotation || !this.quotation.Items || !this.quotationItem || isValueUnchanged) {
            return;
        }

        this.quotationItem.UnitPrice = newValue;

        if (this.quotation.IsLumpSum === this.serviceCallQuotationPricingType.LumpSumPlus) {
            this.calculationInProgress = true;
            this.profitMargin = this.serviceCallQuotationPriceService.UpdateItemProfitMargin(this.quotationItem);
            this.calculationInProgress = false;
        }

    }

    public async populateEquipments(filter: string, pagingInfo: PagingInfo): Promise<EquipmentModel[]> {
        return await this.equipmentService.getEquipments(filter, pagingInfo);
    }

    protected initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
            .ensure((x: ServiceCallQuotationItemModel) => x.EquipmentId).required().withMessageKey("err_EquipmentRequired")
            .ensure((x: ServiceCallQuotationItemModel) => x.Quantity).required().withMessageKey("err_QuantityRequired")
            .ensure((x: ServiceCallQuotationItemModel) => x.Quantity).satisfies((value: number) => value > 0).withMessageKey("err_MinQuantity")
            .ensure((x: ServiceCallQuotationItemModel) => x.NonBillableReasonId).required().when((x: ServiceCallQuotationItemModel) => !x.IsBillable).withMessageKey("err_NonBillableReasonRequired")
            .on(this.quotationItem);
    }

    protected async validate(): Promise<boolean> {
        return await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);
    }
}
