import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import materialRequisitionService from "services/materialRequisitionService";

@autoinject()
export class ServiceMaterialRequisition {

    public dispatchId: any;
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public fromHistory: boolean = false;
    public actions: any;
    public serviceType: any;

    public activate(params: any): any {
        this.serviceType = params.serviceType;
        this.dispatchId = params.dispatchId;

        this.fromHistory = queryStringHelper.parse(params.q, "fromHistory");
        this.fromHistory = !!this.fromHistory;

        this.actions = {
            getRequisitions: materialRequisitionService.getMaterialRequisitionsForService.bind(this, this.dispatchId, this.fromHistory)
        };

        const paramsQs = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }
    }
}
