import { autoinject } from "aurelia-framework";
import { default as serviceService } from "services/serviceService";
import queryStringHelper from "helpers/queryStringHelper";

@autoinject
export class ServiceCallTechnicalDetails {
    public dispatchId: any = 0;
    public informations: any = null;
    public serviceType: string = "S";
    public readonly: any = true;
    public fromHistory: any = false;

    public bindViewModel(serviceType: string, dispatchId: number): void {
        this.dispatchId = dispatchId;
        this.serviceType = serviceType;
    }

    public async loadData(): Promise<void> {
        this.informations = await serviceService.getInformation(this.serviceType, this.dispatchId);
    }

    public async activate(params: any): Promise<void> {
        this.readonly = queryStringHelper.parseReadonly(params.q);

        this.fromHistory = queryStringHelper.parse(params.q, "fromHistory");
        if (!this.fromHistory) {
            this.fromHistory = false;
        } else if (this.fromHistory === "true") {
            this.fromHistory = true;
        }

        this.bindViewModel(params.serviceType, params.dispatchId);
        return await this.loadData();
    }
}
