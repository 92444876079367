import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import materialRequisitionService from "services/materialRequisitionService";

@autoinject()
export class ServiceMaterialRequisitionEdit {

    public actions: any = {};
    public dispatchId: number = 0;
    public requisitionId: string = "-1";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;

    public activate(params: any): any {
        this.dispatchId = params.dispatchId;
        this.requisitionId = params.requisitionId ? params.requisitionId : "-1";
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
        this.readonly = queryStringHelper.parseReadonly(params.q);
    }

    public bind(): any {
        this.actions = {
            getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(materialRequisitionService, this.requisitionId),
            saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForService.bind(materialRequisitionService, this.dispatchId),
            setSpecificFields: this.setSpecificFields.bind(this)
        };

    }

    public setSpecificFields(item: MaterialRequisitionModel): any {
        item.DispatchId = this.dispatchId;
        return item;
    }

}
