define(["core/resx"], function(resx) {
    "use strict";

    var exports = {
        getLabel: function(serviceType) {
            return serviceType === 'S' ? resx.localize('ServiceCall') : resx.localize('WorkOrder');
        },

        getCallTypeLabel: function(callType, language) {
            switch (callType) {
                case 'S':
                    return resx.localize('ServiceCall', language);
                case 'M':
                    return resx.localize('MaintenanceCall', language);
                case 'I':
                    return resx.localize('InstallationCall', language);
                case 'C':
                    return resx.localize('TotalCoverageCall', language);
                default:
                    return '';
            }
        },

        getShortWageTypeLabel: function(wageType) {
            switch (+wageType) {
                case 0:
                    return resx.localize('regular');
                case 1:
                    return resx.localize('overtime_Short');
                case 2:
                    return resx.localize('double');
                default:
                    return '';
            }
        },

        getWageTypeLabel: function(wageType, language) {
            switch (+wageType) {
                case 0:
                    return resx.localize('RegularTime', language);
                case 1:
                    return resx.localize('Overtime', language);
                case 2:
                    return resx.localize('DoubleTime', language);
                default:
                    return '';
            }
        },

        getRateTypeLabel: function(rateType, language) {
            switch (rateType) {
                case "A":
                    return resx.localize('RegularTime', language);
                case "B":
                    return resx.localize('Overtime', language);
                case "C":
                    return resx.localize('DoubleTime', language);
                default:
                    return '';
            }
        },

        getDefaultSelect2Labels: function() {
            return {
                loadingMore: function() { return resx.localize("LoadingMoreResults"); },
                searching: function() { return resx.localize("Searching"); },
                inputTooShort: function() { return resx.localize("err_InputTooShort"); },
                noResults: function() { return resx.localize("NoResultsFound"); },
                errorLoading: function() { return resx.localize("err_LoadingResults"); }
            };
        },

        getLanguageAcronym: function(maestroAcronym) {
            switch (maestroAcronym) {
                case 'A':
                    return 'en';
                case 'F':
                    return 'fr';
                default:
                    return null;
            }

        },

        getBreakAndLunchLabel: function(BreakNo, BreakTaken, IsLunch) {
            if(BreakNo !== 0 && !BreakTaken) {
                return `(${resx.localize('BreakWorked')})`;
            }
            if(BreakNo !== 0 && BreakTaken) {
                return `(${resx.localize('BreakTaken')})`;
            }
            if(IsLunch && !BreakTaken) {
                return `(${resx.localize('LunchWorked')})`;
            }
            if(IsLunch && BreakTaken) {
                return`(${resx.localize('LunchTaken')})`;
            }

            return "";
        }
    };

    return exports;
});
