import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject, computedFrom } from "aurelia-framework";
import { AgGridHelper } from "helpers/ag-grid-helper";
import { RouterHelper } from "helpers/router-helper";
import { QuotationEditParameters } from "pages/services/quotations/edit-base";
import { QuotationListBase, QuotationListParameters } from "pages/services/quotations/list-base";
import RouteRepository, { NavigationNew } from "repositories/routeRepository";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import HostContext from "core/host-context";
import { CanCloseWindow } from "interfaces/can-close-window";
import { NotificationHelper } from "helpers/notification-helper";
import { isWorker } from "cluster";
import UserAccessService from "services/user-access-service";
import { ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { EventAggregator } from "aurelia-event-aggregator";
import { ServiceCallQuotationUserValidationModel } from "api/models/company/service-call/service-call-quotation-user-validation-model";

@autoinject()
export class QuotationListFull extends QuotationListBase implements CanCloseWindow {
    //public currentStatus: ServiceCallQuotationStatus[] = [];
    public currentStatus: Array<string | null> | null  = [];
    public readonly quotationStatus: typeof ServiceCallQuotationStatus = ServiceCallQuotationStatus;
    public btnAllQuotes: HTMLTextAreaElement | null = null;

    public btnInProgressQuotes: HTMLTextAreaElement | null = null;
    public btnOnHoldQuotes: HTMLTextAreaElement | null = null;
    public btnSentToCustomerQuotes: HTMLTextAreaElement | null = null;
    public btnAcceptedByCustomerQuotes: HTMLTextAreaElement | null = null;
    public btnClosedQuotes: HTMLTextAreaElement | null = null;

    @computedFrom("securitySettings")
    public get canCreateQuotation(): boolean {
        return this.serviceCallQuotationSecurityService.canCreateQuotation(this.securitySettings);
    }

    constructor(
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        userAccessService: UserAccessService,
        private readonly eventAggregator: EventAggregator,
        private readonly routeRepository: RouteRepository,
        private readonly routerHelper: RouterHelper,
        private readonly agGridHelper: AgGridHelper,
        private readonly hostContext: HostContext,
        private readonly notificationHelper: NotificationHelper
    ) {
        super(serviceCallQuotationService, serviceCallQuotationSecurityService, userAccessService);
    }

    public async bind(): Promise<void> {
        await this.refreshWithStatus();
    }

    public async activate(params: QuotationListParameters): Promise<void> {
        await super.activate(params);
        this.currentStatus = await this.serviceCallQuotationService.getUserGridStatusSelection();
        await Promise.all([
            this.agGridHelper.lazyLoadAgGridModule()
        ]);

        (window as any).CurrentViewModel = this;
    }

    public async canActivate(params: QuotationListParameters): Promise<any> {
        const responseValidation = await this.serviceCallQuotationService.userQuotationValidation();

        if (responseValidation != null && responseValidation.ErrorId !== 0) {
            this.notificationHelper.showErrorKey(`ApiError.${responseValidation.ErrorId}`, undefined, { timeOut: 0, extendedTimeOut: 0 });
            return false;
        }
        return true;
    }

    public async refresh(): Promise<void> {
        await this.refreshWithStatus();
    }

    public async newChildWindow(id: number): Promise<void> {
        const quotationToCopy = this.quotations.find((quote: ServiceCallQuotationDetailsModel) => quote.Id === id);
        if (quotationToCopy) {
            this.navigateToQuotation(null, quotationToCopy.IsWorkOrder, quotationToCopy.Id);
        }
    }

    public onGridRowDoubleClicked(grid: any, data: ServiceCallQuotationDetailsModel): void {
        if (data && data.Id) {
            this.navigateToQuotation(data.Id, data.IsWorkOrder, null);
        }
    }

    public async canClose(): Promise<void> {
        const childWindowsOpenCount = await (this.hostContext as any).viewModel.openChildWindowsCount();

        if (childWindowsOpenCount > 0) {
            await this.notificationHelper.showDialogOkKey("msg_SeveralQuotesStillOpen", "");
        } else {
            (this.hostContext as any).viewModel.closeWindow();
        }
    }

    public async selectedStatus(statuses: ServiceCallQuotationStatus[]): Promise<void> {
        this.currentStatus = statuses;
        await this.serviceCallQuotationService.setUserGridStatusSelection(this.currentStatus);
        await this.refreshWithStatus();
    }

    private async refreshWithStatus(): Promise<void> {
        this.removeFocusClassOnBtn();
        this.addFocusOnCurrentSelectedButton();
        
        if (!this.currentStatus || this.currentStatus.length === 0) {
            this.quotations = await this.serviceCallQuotationService.getQuotations(null, this.sourceServiceCallId, null, null, false, false, this.sourceWorkOrderId);
        } else {
            this.quotations = await this.serviceCallQuotationService.getQuotations(null, this.sourceServiceCallId, this.currentStatus, null, false, false, this.sourceWorkOrderId);
        }
       
        this.eventAggregator.publish("ApplyFilter");
    }

    private addFocusOnCurrentSelectedButton(): void {
        if (!this.currentStatus || this.currentStatus.length === 0) {
            if (this.btnAllQuotes) {
                this.btnAllQuotes.classList.add("focus");
            }
        }

        if (this.currentStatus!.find((status: ServiceCallQuotationStatus) => status === ServiceCallQuotationStatus.InProgressByTheTechnician)) {
            if (this.btnInProgressQuotes) {
                this.btnInProgressQuotes.classList.add("focus");
            }
        }

        if (this.currentStatus!.find((status: ServiceCallQuotationStatus) => status === ServiceCallQuotationStatus.OnHold)) {
            if (this.btnOnHoldQuotes) {
                this.btnOnHoldQuotes.classList.add("focus");
            }
        }

        if (this.currentStatus!.find((status: ServiceCallQuotationStatus) => status === ServiceCallQuotationStatus.SentToCustomer)) {
            if (this.btnSentToCustomerQuotes) {
                this.btnSentToCustomerQuotes.classList.add("focus");
            }
        }

        if (this.currentStatus!.find((status: ServiceCallQuotationStatus) => status === ServiceCallQuotationStatus.AcceptedByTheCustomer)) {
            if (this.btnAcceptedByCustomerQuotes) {
                this.btnAcceptedByCustomerQuotes.classList.add("focus");
            }
        }

        if (this.currentStatus!.find((status: ServiceCallQuotationStatus) => status === ServiceCallQuotationStatus.CallGenerated)) {
            if (this.btnClosedQuotes) {
                this.btnClosedQuotes.classList.add("focus");
            }
        }
    }

    private removeFocusClassOnBtn(): void {
        if (this.btnAllQuotes) {
            this.btnAllQuotes.classList.remove("focus");
        }

        if (this.btnInProgressQuotes) {
            this.btnInProgressQuotes.classList.remove("focus");
        }

        if (this.btnOnHoldQuotes) {
            this.btnOnHoldQuotes.classList.remove("focus");
        }

        if (this.btnSentToCustomerQuotes) {
            this.btnSentToCustomerQuotes.classList.remove("focus");
        }

        if (this.btnAcceptedByCustomerQuotes) {
            this.btnAcceptedByCustomerQuotes.classList.remove("focus");
        }

        if (this.btnClosedQuotes) {
            this.btnClosedQuotes.classList.remove("focus");
        }

        if (!this.currentStatus || this.currentStatus.length === 0) {
            if (this.btnAllQuotes) {
                this.btnAllQuotes.classList.add("focus");
            }
        }
    }

    private async initQuotations(): Promise<void> {
        this.quotations = await this.serviceCallQuotationService.getQuotations(null, this.sourceServiceCallId, null, null, false, false, this.sourceWorkOrderId);
    }

    private navigateToQuotation(quotationId: number | null, isWorkOrder: boolean, sourceQuotationId: number | null): void {
        this.routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Desktop.name, { quotationId: quotationId ? quotationId : NavigationNew, isWorkOrder: isWorkOrder, sourceServiceCallId: this.sourceServiceCallId ? this.sourceServiceCallId : undefined, sourceWorkOrderId: this.sourceWorkOrderId ? this.sourceWorkOrderId : undefined, sourceQuotationId: sourceQuotationId ? sourceQuotationId : undefined} as QuotationEditParameters);
    }
}
