import stringify from "json-stringify-safe";
import { ServiceCallTimesheetService } from "services/service-call-timesheet-service";
import { ServiceDispatchType } from "enums/service-dispatch-type";
import { default as stringHelper } from "helpers/stringHelper";

define([
    "jquery",
    "knockout",
    "underscore",
    "helpers/dateHelper",
    "helpers/enumHelper",
    "helpers/labelHelper",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "moment",
    "services/defaultService",
    "core/resx",
    "core/val",
    "services/employeeService",
    "services/serviceService",
    "services/commonService",
    "services/bonusService",
    "services/templateService",
    "helpers/notificationHelper",
    "helpers/settingHelper",
    "helpers/wageSettingHelper",
    "services/defaultService",
    "services/tradesService",
    "knockout.validation",
    "select2"
], function (jQuery, ko, _, dateHelper, enumHelper, labelHelper, queryStringHelper, routerHelper, moment, defaultValues, resx, val, employeeService, services, common, bonus, templateService, notifier, settingHelper, wageSettingHelper, defaultService, tradesService) {
    "use strict";
    PLATFORM.moduleName('widgets/predefinedSentences');
    var viewModel = (function () {
        var self = null;

        ctor.inject = [ServiceCallTimesheetService, ServiceDispatchType]

        function ctor(serviceCallTimesheetService, serviceDispatchType) {
            self = this;

            self.serviceCallTimesheetService = serviceCallTimesheetService;
            self.serviceDispatchType = serviceDispatchType;

            //#region Properties
            self.resx = resx;
            self.val = val;
            self.settingHelper = settingHelper;

            self.activityToggleModal = ko.observable(false);
            self.employeeToggleModal = ko.observable(false);

            self.btnText = ko.observable();
            self.dispatchId = ko.observable();
            self.estimatedTime = ko.observable();
            self.id = 0;
            self.shiftList = ko.observableArray([]);
            self.specialEquipment = ko.observable("");
            self.readonly = false;
            self.timesheetTotalTime = ko.observable();
            self.unit = ko.observable("");
            self.increment = ko.observable();
            self.type = ko.observable("");
            self.wage = ko.observableArray();
            self.templateConfigs = ko.observable();
            self.showBonus = ko.observable(false);
            self.editionMode = ko.observable(false);
            self.displayGroup = false;
            self.isEquipment = ko.observable(false);

            self.showEmployeeSection = ko.observable(true);
            self.showEquipmentSection = ko.observable(false);

            self.hasSeperatedEquipments = ko.observable(false);

            self.lookupActivity = {
                transport: function(params, success, failure) {
                    loadActivities(params)
                        .done(success)
                        .fail(failure);
                }
            };

            self.lookupEmployee = {
                transport: function(params, success, failure) {
                    loadEmployee(params)
                        .done(success)
                        .fail(failure => {
                            if (stringHelper.startsWith(failure.responseJSON.Exception.Message, "Dispatch model not found:")) {
                                notifier.showError(resx.localize("DispatchModel") + " : " + failure.responseJSON.Exception.Message.split(':')[1], "", { timeOut: 0 });
                            }
                        });
                },
                mapResults: function(item) {
                    return { id: item.Id, text: item.Id + " - " + item.FirstName + " " + item.LastName, data: item };
                }
            };

            self.lookupBonus = {
                transport: function(params, success, failure) {
                    loadBonus(params)
                        .done(success)
                        .fail(failure);
                }
            };

            self.lookupGroup = {
                transport: function(params, success, failure) {
                    services.getGroup(params.data.filter, params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function(item) {
                    return { id: item.Id, text: item.Id + " - " + item.Description, data: item };
                }
            };

            self.lookupEquipment = {
                transport: function(params, success, failure) {
                    self.serviceCallTimesheetService.GetSeperateTimeLinkedEquipmentDispatches(self.validatedForm().serviceType() === self.serviceDispatchType.Service, self.dispatchId(), params.data.filter, params.data.page || 1)
                        .done(success);
                },
                mapResults: function(item) {
                    return { id: item.Code, text: item.Code + " - " + item.Description, data: item };
                }
            };

            self.validatedForm = ko.validatedObservable({
                activity: ko.observable(),
                bonus: ko.observable(),
                bonusConfigs: ko.observableArray([]),
                bonusDesc: ko.observable(),
                bonusQty: ko.observable(null),
                employee: ko.observable(),
                group: ko.observable(),
                comment: ko.observable(),
                comment_bonus: ko.observable(),
                serviceType: ko.observable(""),
                shift: ko.observable(""),
                wageType: ko.observable(),
                workTime: ko.observable(),
                trade: ko.observable(),
                timestamp: '',
                equipmentCode: ko.observable(),
                equipmentActivityCode: ko.observable(),
                equipmentGroupCode: ko.observable(),
                equipmentWorkTime: ko.observable(),

                init: function() {
                    var valForm = this;
                    valForm.activity = valForm.activity.extend({
                        required: { onlyIf: self.showEmployeeSection, params: true, message: resx.localize("SelectAnActivity") }
                    });

                    valForm.employee = valForm.employee.extend({
                        required: { onlyIf: function() {  return self.isLaborType() && self.showEmployeeSection(); }, message: resx.localize("SelectAnEmployee") }
                    });
                    valForm.employee.subscribe(function (selectedEmployee) {
                        if (selectedEmployee) {
                            getEmployeeDefaultTrade(selectedEmployee.id);
                        }
                    });
                    valForm.workTime = this.workTime.extend({
                        validation: {
                            validator: function(val) {
                                if (!self.showEmployeeSection()) { return true; }

                                if (moment(val).diff(moment(self.emptyDate()), "minutes") === 0 &&
                                    valForm.bonusQty() * 1 === 0 &&
                                    valForm.serviceType() === self.serviceDispatchType.Service) {
                                    this.message = resx.localize("err_TimeSpentOrBonusRequired");
                                    return false;
                                }
                                if (moment(val).diff(moment(self.emptyDate()), "minutes") === 0 &&
                                    _.filter(valForm.bonusConfigs(),
                                        function(b) { return b.value() === true || b.value() > 0; }).length ===
                                    0 &&
                                    valForm.serviceType() === self.serviceDispatchType.Workorder) {
                                    this.message = resx.localize("err_TimeSpentOrBonusRequired");
                                    return false;
                                }

                                if (moment(val).diff(moment(self.emptyDate()), "minutes") === 0 &&
                                    _.filter(valForm.bonusConfigs(),
                                        function(b) { return b.value() === true && b.type === 3; }).length >
                                    0 &&
                                    valForm.serviceType() === self.serviceDispatchType.Workorder) {
                                    this.message = resx.localize("err_TimeSpentWithBonusHoursQty");
                                    return false;
                                }

                                if (moment(val).diff(moment(self.emptyDate()), "minutes") !== 0 &&
                                    valForm.bonusQty() * 1 !== 0) {
                                    this.message = resx.localize("err_TimeSpentOrBonus");
                                    return false;
                                }
                                if (moment(val).diff(moment(self.emptyDate()), "minutes") !== 0 && valForm.bonus()) {
                                    this.message = resx.localize("err_TimeSpentNotWithBonus");
                                    return false;
                                }
                                return true;
                            }
                        }
                    });

                    valForm.shift = valForm.shift.extend({
                        required: { onlyIf: self.showEmployeeSection, params: true, message: resx.localize("SelectAShift") }
                    });
                    valForm.bonusQty.extend({
                        validation: {
                            validator: function(val) {
                                if (val * 1 > 0 && !valForm.bonus()) {
                                    this.message = resx.localize("err_BonusRequired");
                                    return false;
                                }
                                if (val * 1 === 0 && valForm.bonus()) {
                                    this.message = resx.localize("err_BonusQtyRequired");
                                    return false;
                                }
                                return true;
                            }
                        }
                    });

                    valForm.equipmentActivityCode = valForm.equipmentActivityCode.extend({
                        required: { onlyIf: function() { return self.showEquipmentSection() && valForm.equipmentCode(); }, params: true, message: resx.localize("SelectAnActivity") }
                    });

                    valForm.equipmentGroupCode = valForm.equipmentGroupCode.extend({
                        required: { onlyIf: function() { return self.showEquipmentSection() && valForm.equipmentCode() && valForm.serviceType() === self.serviceDispatchType.Workorder; }, params: true, message: resx.localize("SelectAGroup") }
                    });

                    valForm.equipmentWorkTime = this.equipmentWorkTime.extend({
                        validation: {
                            validator: function(val) {
                                var doValidation = self.showEquipmentSection() && valForm.equipmentCode();
                                if (moment(val).diff(moment(self.emptyDate()), "minutes") === 0 && doValidation) {
                                    this.message = resx.localize("err_TimeSpentRequired");
                                    return false;
                                }
                                return true;
                            }
                        }
                    });
                },

                clear: function() {
                    var valForm = this;

                    var shiftToUse = defaultValues.getServiceLastShiftUsed() ||
                        (self.shiftList().length === 0 ? 0 : undefined);
                    valForm.wageType(enumHelper.wageType().SIMPLE);
                    valForm.comment("");
                    valForm.comment_bonus("");
                    valForm.activity(null);
                    valForm.employee(null);
                    valForm.shift(shiftToUse);
                    valForm.group(null);
                    valForm.bonus(null);
                    valForm.bonusDesc(null);
                    valForm.bonusQty(null);
                    valForm.trade(null);

                    valForm.equipmentActivityCode(null);
                    valForm.equipmentGroupCode(null);
                    valForm.equipmentWorkTime(self.emptyDate());

                    valForm.workTime(self.emptyDate());
                }
            });

            self.isTimeType = ko.computed(function() {
                return self.type() !== 'labor';
            });

            self.isLaborType = ko.computed(function() {
                return self.type() === 'labor';
            });
            //#endregion
        }

        //#region Private Functions
        function loadTemplateIncrement() {
            return templateService.getCurrentDispatchIncrement().done(self.increment);
        }

        function bindViewModel(serviceType, dispatchId, lineNo, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.type(queryStringHelper.parse(querystring, 'type'));
            self.showBonus(false);
            self.validatedForm().serviceType(serviceType);
            self.dispatchId(dispatchId);
            self.id = lineNo;
            wageSettingHelper.getDispatchTemplateConfigs("Services", null).done(function (templateConfigs) {
                self.templateConfigs(templateConfigs);
                self.wage(wageSettingHelper.getWageFromTemplateconfigs("Services", self.templateConfigs()));
            });
            self.btnText(resx.localize('Save'));
            self.editionMode(false);
            self.displayGroup = false;

        }

        async function loadData() {
            await loadShifts();
            self.validatedForm().init();
            if (!self.editionMode() && self.type() === "time") {
                getCurrentEmployeeDefaultTrade();
            }

            await Promise.all([
                loadHeader(),
                loadBonusConfig(),
                loadLabor(),
                allowGroup(),
                loadTemplateIncrement()
            ]);

        }

        function allowGroup() {
            if (self.validatedForm().serviceType() !== self.serviceDispatchType.Workorder) {
                return false;
            }

            return templateService.getCurrentDispatchAllowGroup().done(function (displayGroupValue) {
                self.displayGroup = displayGroupValue;
            });
        }

        function initialize(data) {
            self.editionMode(true);
            var date = dateHelper.getDate();

            self.validatedForm().timestamp = data.Timestamp;
            self.validatedForm().shift(data.Shift);

            if (self.showEmployeeSection()) {
                self.validatedForm().wageType(data.WageType);
                self.validatedForm().comment(data.Comment);
                self.validatedForm().activity({ id: data.ActivityCode, text: data.ActivityCode + " - " + data.ActivityDesc });
                self.validatedForm().employee({ id: data.Employee.Id, text: data.Employee.FirstName + " " + data.Employee.LastName });
            }

            if (self.showEquipmentSection()) {
                self.validatedForm().equipmentCode({id: data.EquipmentCode, text: data.EquipmentCode + " - " + data.EquipmentDesc});
                self.validatedForm().equipmentActivityCode({id: data.EquipmentActivityCode, text: data.EquipmentActivityCode + " - " + data.EquipmentActivityDesc});

                if (self.validatedForm().serviceType() === self.serviceDispatchType.Workorder) {
                    self.validatedForm().equipmentGroupCode({id: data.EquipmentGroupCode, text: data.EquipmentGroupCode + " - " + data.EquipmentGroupDesc});
                }

                self.validatedForm().equipmentWorkTime(date.setMinutes(Math.round(data.EquipmentWorkTime * 60)));
            }

            self.unit(data.BonusUnit);
            if (data.TradeCode) {
                self.validatedForm().trade({ id: data.TradeCode, text: data.TradeCode + " - " + data.TradeDescription });
            }
            self.validatedForm().group({ id: data.Group, text: data.Group + " - " + data.GroupDescription });
            if (self.validatedForm().serviceType() === self.serviceDispatchType.Service) {
                //service call
                if (data.Bonus !== "") {
                    self.show('bonus');
                    self.validatedForm().bonus({ id: data.Bonus, text: data.Bonus + " - " + data.BonusDesc });
                    self.validatedForm().comment_bonus(data.Comment);
                } else {
                    self.validatedForm().comment(data.Comment);
                    self.validatedForm().bonus(null);

                }

                self.validatedForm().bonusDesc(data.BonusDesc);

                if (data.Bonus === "") {
                    date.setMinutes(Math.round(data.WorkTime * 60));
                    self.validatedForm().bonusQty(null);
                } else {
                    self.validatedForm().bonusQty(data.WorkTime);
                }
            } else {
                //workorder
                date.setMinutes(Math.round(data.WorkTime * 60));
                //set bonus values
                initBonusValueWorkOrder(data);
            }

            self.validatedForm().workTime(date);
            self.readonly = self.readonly || data.IsReadonly;
        }

        function setEmployeeDefaultTrade(data) {
            if (!self.editionMode()) {
                self.validatedForm().trade(data ? { id: data.Code, text: data.Code + " - " + data.Description } : null);
            }
        }

        function initBonusValueWorkOrder(data) {
            _.each(data.Bonus, function (b) {
                var bonusCfg = _.find(self.validatedForm().bonusConfigs(), function (bCfg) { return bCfg.code === b.Code; });
                if (bonusCfg.type === 1) {
                    bonusCfg.value(b.Value);
                } else {
                    bonusCfg.value(b.Value === 1);
                }
                bonusCfg.id = b.Id;
            });
        }

        function initDropDownLists() {
            jQuery("#ddlShift").select2({
                minimumResultsForSearch: defaultValues.getMinimumResultsForSearch(),
                allowClear: false,
                placeholder: resx.localize("SelectOoo"),
                width: "100%",
                language: labelHelper.getDefaultSelect2Labels(),
                data: self.shiftList()
            }).on("change", function (e) {
                self.validatedForm().shift(e.target.value);
            }).val(self.validatedForm().shift()).trigger("change");

        }

        async function loadLabor() {
            const result  = await self.serviceCallTimesheetService.GetSeperateTimeLinkedEquipmentDispatches(self.validatedForm().serviceType() === self.serviceDispatchType.Service, self.dispatchId());
            self.hasSeperatedEquipments(result.length > 0);

            if (self.id) {
                return services.getLabor(self.validatedForm().serviceType(), self.dispatchId(), self.id).done(data => {
                    self.showEmployeeSection(data.IsEmployee);
                    self.showEquipmentSection(data.IsEquipment);
                    initialize(data)
                });
            } else {
                self.showEquipmentSection(self.hasSeperatedEquipments() && !self.isLaborType());
                self.validatedForm().clear();
                return jQuery.Deferred().resolve();
            }
        }

        function getEmployeeDefaultTrade(employeeId) {
            if (employeeId) {
                return tradesService.getEmployeeDefaultTrade(employeeId).done(setEmployeeDefaultTrade);
            } else {
                return jQuery.Deferred().resolve();
            }
        }

        function getCurrentEmployeeDefaultTrade() {
            return tradesService.getCurrentEmployeeDefaultTrade().done(setEmployeeDefaultTrade);
        }

        async function loadShifts() {
            if (defaultValues.getServiceLastShiftUsed() === undefined || defaultValues.getServiceLastShiftUsed() === null) {
                //get the last used value !!
                let lastShift = await services.getLastShiftUsed(self.validatedForm().serviceType());
                if (self.shiftList().length > 0) {
                    defaultValues.setServiceLastShiftUsed(lastShift);
                }
            }

            let shifList = await common.getShifts();
            shifList = _.map(shifList, function (shift) {
                return { id: shift.Id, text: shift.Id + " - " + shift.Description };
            });
            self.shiftList(shifList);
            let lastShiftUsed = isNaN(parseInt(defaultValues.getServiceLastShiftUsed())) ? 0 : parseInt(defaultValues.getServiceLastShiftUsed());
            let shiftToFind = self.shiftList().find(shiftItem => shiftItem.id === lastShiftUsed);
            if (!shiftToFind && self.shiftList().length > 0) {
                defaultValues.setServiceLastShiftUsed(_.first(self.shiftList()).id);
            }
        }

        function loadHeader() {
            return services.getLaborsHeader(self.validatedForm().serviceType(), self.dispatchId()).done(function (data) {
                self.specialEquipment(data.SpecialEquipment);
                self.timesheetTotalTime(dateHelper.hoursTohmm(data.TimesheetTotalTime));
                self.estimatedTime(dateHelper.hoursTohmm(data.EstimatedTime));
            });
        }

        function loadBonusConfig() {
            if (self.validatedForm().serviceType() === self.serviceDispatchType.Workorder) {
                return bonus.getBonusConfigs().done(function (value) {
                    var list = _.map(value, function (data) {
                        return {
                            id: 0,
                            code: data.Code,
                            value: ko.observable(),
                            type: data.ColumnType,
                            description: data.Description,
                            unit:data.Unit
                        };
                    });
                    self.validatedForm().bonusConfigs(list);
                });
            }
            return { done: function () { return null; } };
        }

        function loadBonus(params) {
            if (self.validatedForm().serviceType() === self.serviceDispatchType.Service) {
                return bonus.getBonuses(params.data.filter);
            }
            return null;
        }

        function loadActivities(params) {
            if (self.validatedForm().serviceType() === self.serviceDispatchType.Service) {
                return services.getActivities(self.serviceDispatchType.Service, params ? params.data.filter : "");
            } else {
                return services.getActivitiesByDispatch(self.serviceDispatchType.Workorder, self.dispatchId(), enumHelper.workOrderActivityType().LABOUR, params? params.data.filter : "");
            }
        }

        function loadEmployee(params) {
            return employeeService.getAvailableEmployeeForTimesheet(self.validatedForm().serviceType(), self.dispatchId(), defaultValues.getCurrentDispatchTemplateCode(), params.data.filter, params.data.page || 1);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.unit("");
            bindViewModel(params.serviceType, params.dispatchId, params.lineNo, params.q);

            self.employeeToggleModal(!self.id && self.isLaborType());
            self.activityToggleModal(!self.id && !self.isLaborType());

            return loadData();
        };

        ctor.prototype.attached = function () {
            initDropDownLists();
        };

        ctor.prototype.emptyDate = function () {
            return dateHelper.getDate().toISOString();
        };

        ctor.prototype.onSelectBonus = function (selectedItem) {
            self.validatedForm().bonusQty(1);
            self.unit(selectedItem.data.Unit);
        };

        ctor.prototype.onUnSelectBonus = function () {
            self.unit("");
        };

        ctor.prototype.onSelectEquipment = async function (item) {
            if (self.validatedForm().serviceType() === self.serviceDispatchType.Workorder && item.data.DefaultGroupId) {
                const groups = await services.getGroup();

                const defaultGroup = groups.find(x => x.Id === item.data.DefaultGroupId);
                if (defaultGroup) {
                    self.validatedForm().equipmentGroupCode({id: defaultGroup.Id, text: defaultGroup.Id + " - " + defaultGroup.Description});
                }
            }

            if (item.data.DefaultActivityId) {
                const activities = await loadActivities();

                const defaultActivity = activities.find(x => x.Id === item.data.DefaultActivityId);
                if (defaultActivity) {
                    self.validatedForm().equipmentActivityCode({id: defaultActivity.Id, text: defaultActivity.Id + " - " + defaultActivity.Description});
                }
            }
        }

        ctor.prototype.show = function (section) {
            self.showBonus(section === "bonus");
        };

        ctor.prototype.getTrades = {
            transport: function (params, success, failure) {
                if (self.validatedForm().employee()) {
                    tradesService.getTradesForEmployee(self.validatedForm().employee().id, params.data.filter, params.data.page || 1)
                        .done(function (data) {
                            return success(_.map(data, function (x) {
                                x.Id = x.Code;
                                return x;
                            }));
                        })
                        .fail(failure);
                } else if (self.type() === "time") {
                    tradesService.getTradesForDispatch(self.dispatchId(), params.data.filter, params.data.page || 1)
                        .done(function (data) {
                            return success(_.map(data, function (x) {
                                x.Id = x.Code;
                                return x;
                            }));
                        })
                        .fail(failure);
                }
            }
        };

        ctor.prototype.save = function () {

            if (self.showBonus()) {
                self.validatedForm().equipmentCode(null);
                self.validatedForm().equipmentActivityCode(null);
                self.validatedForm().equipmentGroupCode(null);
                self.validatedForm().equipmentWorkTime(self.emptyDate());
            }

            if (self.shiftList().length > 0 && !self.validatedForm().shift() && self.showBonus()) {
                self.validatedForm().shift(_.first(self.shiftList()).id);
            }

            if (self.validatedForm.isValid()) {
                routerHelper.showLoading();
                var model = self.validatedForm();
                var workTime = moment(model.workTime()).diff(moment(self.emptyDate()), "minutes");
                var currentBonus;
                var wageType = model.wageType() || 0;
                var timesheet = {
                    LineNo: self.id || 0,
                    Timestamp: self.id ? model.timestamp : undefined,
                    ActivityCode: self.showEmployeeSection() ? model.activity().id : "",
                    TradeCode: model.trade() ? model.trade().id : 0
                };
                if (self.validatedForm().serviceType() === self.serviceDispatchType.Service) {
                    currentBonus = model.bonus() === null ? "" : model.bonus().id;
                    if (self.showBonus()) {
                        //value for P
                        timesheet.WageType = 3;
                        timesheet.WorkTime = model.bonusQty() * 1;
                        timesheet.Comment = model.comment_bonus();
                        timesheet.Bonus = currentBonus;
                        if (self.shiftList().length > 0) {
                            timesheet.Shift = _.first(self.shiftList()).id;
                        } else {
                            timesheet.Shift = model.shift();
                        }

                    } else {
                        timesheet.WageType = wageType;
                        timesheet.WorkTime = workTime > 0 ? workTime / 60 : model.bonusQty() * 1;
                        timesheet.Comment = model.comment();
                        timesheet.Shift = model.shift();
                    }
                } else {
                    currentBonus = _.map(self.validatedForm().bonusConfigs(), function (data) {
                        return {
                            id: data.id,
                            code: data.code,
                            value: data.type === 1 ? data.value() : (data.value() ? 1 : 0),
                            type: data.type
                        };
                    });

                    timesheet.WageType = wageType;
                    if (self.showEquipmentSection() && !self.showEmployeeSection()) {
                        timesheet.WorkTime = 0;
                    } else {
                        timesheet.WorkTime = workTime > 0 ? workTime / 60 : model.bonusQty() * 1;
                    }

                    timesheet.Comment = model.comment();
                    timesheet.Shift = model.shift();
                    timesheet.Bonus = currentBonus;
                    timesheet.Group = model.group() ? model.group().id : "";
                }

                if (self.showEquipmentSection()) {
                    timesheet.EquipmentCode = model.equipmentCode() ? model.equipmentCode().id : "";
                    timesheet.EquipmentActivityCode = model.equipmentActivityCode() ? model.equipmentActivityCode().id : "";
                    timesheet.EquipmentGroupCode = model.equipmentGroupCode() ? model.equipmentGroupCode().id : "";

                    var equipmentWorkTime = moment(model.equipmentWorkTime()).diff(moment(self.emptyDate()), "minutes");
                    timesheet.EquipmentWorkTime = equipmentWorkTime > 0 ? equipmentWorkTime / 60 : 0;
                }

                if (self.isLaborType() && self.showEmployeeSection()) {
                    timesheet.Employee = { Id: self.validatedForm().employee().id };
                }

                var data = {
                    Timesheets: [timesheet]
                };

                //set lastshifused
                services.setLabor(self.validatedForm().serviceType(), self.dispatchId(), data).done(function () {
                    if (self.shiftList().length > 0) {
                        defaultValues.setServiceLastShiftUsed(model.shift());
                    }
                    routerHelper.navigateBack();
                })
                    .concurrence(loadLabor)
                    .always(function () {
                        routerHelper.hideLoading();
                    });

            } else {
                notifier.showValidationError(self.validatedForm.errors);
            }
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
