import { MaterialRequisitionItemModel } from "api/models/company/material-requisition/material-requisition-item-model";
import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import enumHelper from "helpers/enumHelper";
import routerHelper from "helpers/routerHelper";
import { StringHelper } from "helpers/string-helper";
import moment from "moment";
import notificationHelper from "helpers/notificationHelper";
import materialRequisitionService from "services/materialRequisitionService";
import { MaterialRequisitionSummaryModel } from "api/models/company/material-requisition/material-requisition-summary-model";
import labelHelper from "helpers/labelHelper";
import { UserSecurityLookupModel } from "api/models/common/settings/user-security-lookup-model";
import userService from "services/userService";

@autoinject
export class MaterialRequisitionEdit {

    public dateHelper: typeof dateHelper = dateHelper;
    public enumHelper: typeof enumHelper = enumHelper;
    public routerHelper: typeof routerHelper = routerHelper;

    @bindable public actions: any = {};
    @bindable public readonly: boolean = false;
    @bindable public requisitionId: string = "-1";
    @bindable public dispatchProjectCode: string = "";

    public dateEntered: Date = moment().toDate();
    public dateRequired: Date = moment().toDate();
    public isNew: boolean = false; //todo verify if true or false at init
    public requisition!: MaterialRequisitionModel;
    public status: string = "";
    public btnText: string = "";
    public requisitionIdDisplay: string = "";
    public reqItemsVisible: any;
    public txtNoReqItem: string = "";
    public isAddItemDisabled: boolean = true; //todo verify if true or false at init
    public isAddResourceDisabled: boolean = true; //todo verify if true or false at init
    public isFromProjectModule: boolean = false;
    public statusList: Array<{ text: string; id: number; }> = [];
    public selectedUser: any | null = null;

    constructor(private readonly i18n: I18N) {
    }

    public async bind(): Promise<any> {
        await this.loadData();
        this.statusList = [{ text: this.i18n.tr("Ongoing"), id: 0 }, { text: this.i18n.tr("Completed_F"), id: 1 }];
    }

    public genUrl(line: number): string {
        return routerHelper.getRelativeUrl("item", dateHelper.dateToNavigationParameter(this.dateRequired), line) + routerHelper.addQuerystring({ readonly: this.readonly });
    }

    public addUrl(): string {
        const date = dateHelper.dateToNavigationParameter(this.dateRequired);
        let temp = routerHelper.getRelativeUrl("");

        if (StringHelper.endsWith(temp, "edit")) {
            temp += "/";
            temp += this.requisitionId;
            temp += "/item/";
            temp += date;

            return temp;
        }
        return routerHelper.getRelativeUrl("item", date);
    }

    public addProjectResourceUrl(): string {
        const date = dateHelper.dateToNavigationParameter(this.dateRequired);
        let temp = routerHelper.getRelativeUrl("");

        if (StringHelper.endsWith(temp, "edit")) {
            temp += "/";
            temp += this.requisitionId;
            temp += "/projectResource/";
            temp += date;

            return temp;
        }
        return routerHelper.getRelativeUrl("projectResource", date);
    }

    public deleteItem(item: MaterialRequisitionItemModel): void {
        notificationHelper.showDeleteConfirmation().then((success: any) => {
            if (success) {
                routerHelper.showLoading();
                materialRequisitionService.delMaterialRequisitionItem(item.Id!, item.Line).then(() => {
                    this.loadData();
                });
                routerHelper.hideLoading();
            }
        });
    }

    public saveRequisition(): void {
        this.requisition.DateEntered = this.dateEntered;
        this.requisition.RequiredDate = this.dateRequired;
        this.requisition.MobileStatus = this.status;
        this.requisition.Id = this.requisitionId === "-1" ? null : this.requisitionId;
        this.requisition.ProcessedBy = this.selectedUser === null ? 0 : this.selectedUser.id;

        this.requisition = this.actions.setSpecificFields(this.requisition);

        routerHelper.showLoading();

        this.actions.saveMaterialRequisitionService(this.requisition).then((data: MaterialRequisitionSummaryModel) => {
            this.requisitionId = data.Id!;

            if (this.isNew) {
                this.isNew = false;
                routerHelper.navigate(routerHelper.getRelativeUrl(encodeURIComponent(data.Id!)) + routerHelper.addQuerystring({ readonly: this.readonly }), { replace: true, trigger: true });
            } else {
                routerHelper.navigateBack();
            }
        }).always(() => {
            routerHelper.hideLoading();
        });
    }

    public get lookupUser(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                userService.getAllUsersLookup(params.data.filter, params.data.page || 1).then(
                    (result: UserSecurityLookupModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: UserSecurityLookupModel): any => {
                return { id: item.Id, text: item.Code + " - " + item.Description };
            }
        };
    }

    private async loadData(): Promise<void> {
        await this.actions.getMaterialRequisition().then((result: MaterialRequisitionModel) => {
            this.requisition = result;

            if (this.requisition.Id === null) {
                this.initValuesForNewRequisition();
            } else {
                if (this.requisition.ProcessedBy !== 0) {
                    this.selectedUser = this.createDefaultMaSelectElement(this.requisition.ProcessedBy, this.requisition.ProcessedByName);
                }

                this.isNew = false;
            }

            this.updateSelf();
            this.initDropdown();
        });
    }

    private updateFlags(): void {
        this.readonly = (this.requisition.MobileStatus === "1") || this.readonly;
        this.isAddItemDisabled = this.isNew || this.readonly;

        this.isAddResourceDisabled = this.isAddItemDisabled || !this.requisition.HasResource;
        this.reqItemsVisible = !this.isNew && this.requisition.Items && this.requisition.Items.length > 0;

        this.isFromProjectModule = !!this.dispatchProjectCode;

    }

    private updateTextsAndValues(): void {
        this.btnText = this.isNew ? this.i18n.tr("Add") : this.i18n.tr("Save");
        this.requisitionIdDisplay = this.isNew ? this.i18n.tr("New_F") : this.requisitionId;
        this.txtNoReqItem = this.isNew ? this.i18n.tr("lstNoData_RequisitionItemUnsaved") : this.i18n.tr("lstNoData_RequisitionItem");

        this.status = this.requisition.MobileStatus!;
        this.dateEntered = this.requisition.DateEntered!;
        this.dateRequired = this.requisition.RequiredDate!;
    }

    private updateSelf(): void {
        this.updateFlags();
        this.updateTextsAndValues();
    }

    private createDefaultMaSelectElement(id: number, description: string | null): any {
        return { id: id, text: description ? id + " - " + description : id };
    }

    private initDropdown(): void {
        const element: any = jQuery("#ddlStatus");
        element.select2({
            minimumResultsForSearch: 999,
            width: "100%",
            language: labelHelper.getDefaultSelect2Labels(),
            data: [
                { text: this.i18n.tr("Ongoing"), id: 0 },
                { text: this.i18n.tr("Completed_F"), id: 1 }
            ]
        }).on("change", (e: any) => {
            this.status = e.target.value;
        }).val(this.status).trigger("change");
    }

    private initValuesForNewRequisition(): void {
        this.isNew = true;

        this.requisition.MobileStatus = "0";
        this.requisition.DateEntered = moment().toDate();
        this.requisition.RequiredDate = moment().toDate();
        this.requisition.Prefix = "";
        this.requisition.UserNo = -1;
        this.requisition.Items = [];
    }
}
